<template>
  <validation-observer ref="priceRules">
    <div>
      <b-row>
        <table class="table table-sm ">
          <thead>
            <tr>
              <th scope="col" class="table-primary">Price Calculation</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-row cols="12">

        <b-col sm="6" md="7" xl="6">
          <table class="table border table-sm">

            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Inception Date</td>
              <td scope="col">{{ purchaseQuoteData.inceptionDate ? purchaseQuoteData.inceptionDate : 'N/A' }}</td>
            </tr>

            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Current Number of Payments</td>
              <td scope="col">{{ purchaseQuoteData.currentPmtNo }}</td>
            </tr>

            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Off Road Type</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.offroadType,2) }}</td>
            </tr>
            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Off Road Value</td>

              <td>
                <cleave style="height:2.142rem" v-model="purchaseQuoteData.offroadValue" class="form-control"
                  :raw="true" :options="options.number" />
              </td>
            </tr>

            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Contract Price</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.contractPrice,2) }}</td>
            </tr>

            
            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Inception Paydown</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.inceptionPaydown,2) }}</td>
            </tr>

            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Net Cap</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.netCap,2) }}</td>
            </tr>

            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Accumulated Principal Payment
              </td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.principalPmt,2) }}</td>
            </tr>
            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Accumulated Interest Charge</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.interestCharge,2) }}</td>
            </tr>
            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Accumulated Admin Charge</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.adminCharge,2) }}</td>
            </tr>
    



          </table>
        </b-col>
        <b-col sm="6" md="7" xl="6">
          <table class="table border table-sm">
          
            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Current Principal Balance</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.principalBalance,2) }}</td>
            </tr>
            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Current Admin Balance</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.adminBalance,2) }}</td>
            </tr>
            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Current Buyout</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.buyout,2) }}</td>
            </tr>


            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Off Road Balance&Credit Amount
              </td>
              <td scope="col">{{ formatPrice(offroadBalance,2) }}</td>
            </tr>

            
      

            <tr v-if="purchaseQuoteData.priceContractType != 2">
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">G.S.T Amount on Vehicle</td>
              <td scope="col">{{ formatPrice(this.gstAmountOnVehicle) }} | {{ formatPrice(purchaseQuoteData.gstRateVehicle) }} %</td>
            </tr>



            <tr v-if="purchaseQuoteData.priceContractType != 2">
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">P.S.T Amount on Vehicle</td>
              <td scope="col">{{ formatPrice(this.pstAmountOnVehicle) }}  | {{ formatPrice(purchaseQuoteData.pstRateVehicle) }} %</td>
            </tr>


            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Total Balance&Credit</td>
              <td scope="col">{{ formatPrice(totalBalance,2) }}</td>
            </tr>

            
            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Less Security Deposit</td>
              <td scope="col">{{ formatPrice(purchaseQuoteData.lessSecurity,2) }}</td>
            </tr>



            <tr>
              <td scope="col" class="text-center font-weight-bold" style="width: 70%;">Total Off Road Balance Due</td>
              <td scope="col">{{ formatPrice(totalBalanceDue,2) }}</td>
            </tr>







          </table>
        </b-col>
      </b-row>


    </div>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup, BButton } from 'bootstrap-vue';
import store from '@/store';
import Ripple from 'vue-ripple-directive';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import router from '@/router';
import 'cleave.js/dist/addons/cleave-phone.us';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,
    BButton,

    //validation
    ValidationProvider,
    ValidationObserver,

    VueNumericInput,
    ToastificationContent,
    Cleave,
  },
  directives: {
    Ripple,
  },

  props: {
    purchaseQuoteData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      wherefrom: router.currentRoute.name,
      priceCalculationTemp: {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      },

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  watch: {



    'priceCalculationTemp.pcPrice': {
      handler: function (id, before) {
        if (this.priceCalculationTemp.pcPrice != null || this.priceCalculationTemp.pcGst != '' || this.priceCalculationTemp.pcPst != '') {
          this.priceCalculationTemp.pcGstAmount = (this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst;

          this.priceCalculationTemp.pcPstAmount = (this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst;

          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },

    'priceCalculationTemp.pcGst': {
      handler: function (id, before) {
        if (this.priceCalculationTemp.pcGst != null || this.priceCalculationTemp.pcGst != '') {
          this.priceCalculationTemp.pcGstAmount = (Number(this.priceCalculationTemp.pcPrice) / 100) * Number(this.priceCalculationTemp.pcGst);
          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },

    'priceCalculationTemp.pcPst': {
      handler: function (id, before) {
        if (this.priceCalculationTemp.pcPst != null || this.priceCalculationTemp.pcPst != '') {
          this.priceCalculationTemp.pcPstAmount = (Number(this.priceCalculationTemp.pcPrice) / 100) * Number(this.priceCalculationTemp.pcPst);
          this.priceCalculationTemp.pcSubTotal = Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcPst) + Number((this.priceCalculationTemp.pcPrice / 100) * this.priceCalculationTemp.pcGst) + Number(this.priceCalculationTemp.pcPrice);
        }
      },
    },
  },

  computed: {

    offroadBalance() {
      this.purchaseQuoteData.offroadBalance = this.purchaseQuoteData.buyout - this.purchaseQuoteData.offroadValue
      return this.purchaseQuoteData.offroadBalance;
    },

    totalBalance() {


      this.purchaseQuoteData.totalBalance = this.purchaseQuoteData.offroadBalance + this.gstAmountOnVehicle + this.pstAmountOnVehicle;
      return this.purchaseQuoteData.totalBalance;
    },

    totalBalanceDue() {

      this.purchaseQuoteData.totalBalanceDue = this.purchaseQuoteData.totalBalance - this.purchaseQuoteData.lessSecurity;
      return this.purchaseQuoteData.totalBalanceDue;
    },

    
   




    priceDifference() {
      if (this.purchaseQuoteData.priceContractType == 2) {
        return (this.purchaseQuoteData.priceDifference = this.purchaseQuoteData.appraisedVehiclePrice - this.creditIssued);
      } else {
        return (this.purchaseQuoteData.creditIssued = this.purchaseQuoteData.appraisedVehiclePrice - this.creditIssued);
      }

      // if (this.purchaseQuoteData.appraisedVehiclePrice == null || this.purchaseQuoteData.appraisedVehiclePrice == '') {
      //   return 0;
      // } else if (this.purchaseQuoteData.creditIssued == null || this.purchaseQuoteData.creditIssued == '') {
      //   return Number(this.purchaseQuoteData.appraisedVehiclePrice);
      // } else {
      //   return Number(this.purchaseQuoteData.appraisedVehiclePrice - this.purchaseQuoteData.creditIssued);
      // }
    },

    creditIssued() {
      if (this.purchaseQuoteData.priceContractType == 2) {
        return (this.purchaseQuoteData.creditIssued = this.purchaseQuoteData.appraisedVehiclePrice ? this.purchaseQuoteData.appraisedVehiclePrice : 0);
      } else {
        return (this.purchaseQuoteData.creditIssued = 0);
      }
    },

    payableBySeller() { },

    gstAmountOnVehicle() {
      if (this.offroadBalance == 0) {
        this.purchaseQuoteData.gstRateVehicle = null;
        return 0;
      } else if (this.purchaseQuoteData.gstRateVehicle == null || this.purchaseQuoteData.gstRateVehicle == '') {
        return 0;
      } else {
        this.purchaseQuoteData.gstAmountVehicle = Number((this.offroadBalance / 100) * this.purchaseQuoteData.gstRateVehicle);
        return this.purchaseQuoteData.gstAmountVehicle;

      }
    },

    pstAmountOnVehicle() {
      if (this.offroadBalance == 0) {
        this.purchaseQuoteData.pstRateVehicle = null;
        return 0;
      } else if (this.purchaseQuoteData.pstRateVehicle == null || this.purchaseQuoteData.pstRateVehicle == '') {
        return 0;
      } else {
        this.purchaseQuoteData.pstAmountVehicle = Number((this.offroadBalance / 100) * this.purchaseQuoteData.pstRateVehicle);
        return  this.purchaseQuoteData.pstAmountVehicle;
      }
    },

    optionAmountTotal() {
      var total = 0;
      this.purchaseQuoteData.priceCalculationValues.forEach((element) => {
        total = Number(total) + Number(element.pcSubTotal);
      });

      return total;
    },

    contractAmount() {
      return Number(this.optionAmountTotal) + Number(this.priceDifference) + Number(this.gstAmountOnVehicle);
    },
  },

  methods: {
    priceCalculation() {
      this.purchaseQuoteData.priceCalculationValues.push(this.priceCalculationTemp);
      this.priceCalculationTemp = {
        pcOptions: null,
        pcPrice: null,
        pcGst: null,
        pcPst: null,
        pcGstAmount: null,
        pcPstAmount: null,
        pcSubTotal: null,
      };
    },

    removeAdditionalItem(e) {
      this.$delete(this.purchaseQuoteData.priceCalculationValues, e);
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  },
};
</script>
